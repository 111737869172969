import { useState } from "react";
import useWindowWidth from "../context/WidthContext";
import "./MainMenu.css";

function MainMenu({ listTitles, current, handleChangeSection }) {
  const [unfold, setUnfold] = useState(true);

  const handleClick = (section) => {
    console.log("setSection ", section);
    handleChangeSection(section);
  };

  return (
    <>
      {unfold ? (
        <div className="main-menu">
          <div className="titles-container">
            <img src="arrete_de_regarder_les_noms_des_images.svg"></img>
            {listTitles.map((e, index) => {
              return (
                <span
                  className={
                    "text main-menu-section-title" +
                    (index == current ? " bold" : "")
                  }
                  onClick={() => handleClick(index)}
                >
                  {e}
                </span>
              );
            })}
          </div>
          <img
            src="arrow-left-5-svgrepo-com.svg"
            className="fold-arrow"
            onClick={() => setUnfold(!unfold)}
          ></img>
        </div>
      ) : (
        <div className="main-menu-fold">
          <img
            src="arrow-left-5-svgrepo-com.svg"
            className="unfold-arrow"
            onClick={() => setUnfold(!unfold)}
          ></img>
        </div>
      )}
    </>
  );
}

export default MainMenu;
