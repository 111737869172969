import { useState } from "react";
import "./Wait.css";

function Wait() {
  const [state, setState] = useState(0);
  const MAXSTATES = 2;

  const handleButton = () => {
    var newState = state + 1;
    if (newState >= MAXSTATES) {
      newState = 0;
    }
    setState(newState);
  };

  return (
    <div className="wait-page">
      {state == 0 && (
        <div className="flex-center-column">
          <img src="bientot.png"></img>
          <span>A très bientôt !</span>
        </div>
      )}
      {state == 1 && (
        <div className=".flex-center-row">
          <img src="chiengue.png"></img>
          <img src="marex.png"></img>
        </div>
      )}
      <button onClick={handleButton}>Bouton</button>
    </div>
  );
}

export default Wait;
