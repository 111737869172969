import { useState } from "react";
import MainMenu from "../menu/MainMenu";
import "./MainLayout.css";
import Wait from "../pages/Wait";
import Accueil from "../pages/Accueil";
import Tarifs from "../pages/Tarifs";
import Presentation from "../pages/Presentation";

function MainLayout() {
  const listTitles = [
    "Connexion",
    "Accueil",
    "Chat",
    "Qui sommes-nous ?",
    "Tarifs",
    "Service après-vente",
    "Recettes de cuisine",
    "L'antre de l'artiste",
    "Test de personnalité",
    "Horoscope 🌠🔮",
    "Coordonnées bancaires et CNI de nos utilisateurs",
    "Nos tueurs à gage 😈🔪🩸",
    "Météo ☀️🌧️",
    "Générateur de compliments 😊",
    "Défi Rubik's Cube",
    "Quiz",
    "ILIES 😡🖕",  
    "Ventilateur",
    "Salle de bain"

  ];
  const [currentSection, setCurrentSection] = useState(1);

  const getSection = () => {
    if (currentSection == 0) return <Wait></Wait>;
    if (currentSection == 1) return <Accueil></Accueil>;
    if (currentSection == 2) return <Wait></Wait>;
    if (currentSection == 3) return <Presentation></Presentation>;
    if (currentSection == 4) return <Tarifs></Tarifs>;
    if (currentSection == 5) return <Wait></Wait>;
    if (currentSection == 6) return <Wait></Wait>;
    if (currentSection == 7) return <Wait></Wait>;
    if (currentSection == 8) return <Wait></Wait>;
    if (currentSection == 9) return <Wait></Wait>;
    if (currentSection == 10) return <Wait></Wait>;
    if (currentSection == 11) return <Wait></Wait>;
    if (currentSection == 12) return <Wait></Wait>;
    if (currentSection == 13) return <Wait></Wait>;
    if (currentSection == 14) return <Wait></Wait>;
    if (currentSection == 15) return <Wait></Wait>;
    if (currentSection == 16) return <Wait></Wait>;
    if (currentSection == 17) return <Wait></Wait>;
    if (currentSection == 18) return <Wait></Wait>;
    if (currentSection == 19) return <Wait></Wait>;
    if (currentSection == 20) return <Wait></Wait>;
  };

  return (
    <div className="main-layout">
      <MainMenu
        listTitles={listTitles}
        current={currentSection}
        handleChangeSection={setCurrentSection}
      ></MainMenu>
      <div className="main-layout-section">{getSection()}</div>
    </div>
  );
}

export default MainLayout;
