import "./App.css";
import useWindowWidth from "./context/WidthContext";
import MainLayout from "./layout/MainLayout";
import Reviens from "./pages/Reviens";
import Wait from "./pages/Wait";
import { useState, useEffect } from "react";

function App() {
  const windowWidth = useWindowWidth();
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setShowFullScreenImage(true);
      }
    };

    const handleMouseOver = (event) => {
      if (
        event.clientX >= 0 &&
        event.clientY >= 0 &&
        event.clientX <= window.innerWidth &&
        event.clientY <= window.innerHeight
      ) {
        setShowFullScreenImage(false);
      }
    };

    window.addEventListener('mouseout', handleMouseOut);
    window.addEventListener('mouseover', handleMouseOver);

    return () => {
      window.removeEventListener('mouseout', handleMouseOut);
      window.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);
  return (
    <div className="App">
      {windowWidth < 800 ? (
        <div>
          <img src="marex.png" className="image-responsive" alt="marex"></img>
          <div className="mobile-warning">
            Ça marche pas sur tel, viens sur PC
          </div>
        </div>
      ) : (
        showFullScreenImage && false? (
          <Reviens></Reviens>
        )
        :
        <MainLayout></MainLayout>
      )}
    </div>
  );
}

export default App;
