import { useState } from "react";
import "./Presentation.css";

function Presentation() {
  return (
    <div className="presentation-wrapper">
      <div className="presentation-layout">
        <div className="flex-center-column presentation-column">
          <img className="presentation-img" src="romulus.png"></img>
          <h2>Romulus</h2>
          <div class="table-container">
            <table class="table">
              <tbody>
                <tr>
                  <td>Prénom</td>
                  <td>Romulus</td>
                </tr>
                <tr>
                  <td>Nom</td>
                  <td>BORCOIN</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>47</td>
                </tr>
                <tr>
                  <td>Localisation</td>
                  <td>activée</td>
                </tr>
                <tr>
                  <td>Métier</td>
                  <td>pisseur de code</td>
                </tr>
                <tr>
                  <td>Taille</td>
                  <td>1m57</td>
                </tr>
                <tr>
                  <td>Pointure</td>
                  <td>42.5</td>
                </tr>
                <tr>
                  <td>J'aime</td>
                  <td>
                    <ul>
                      <li>le bitcoin</li>
                      <li>la nissan r34</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Je n'aime pas</td>
                  <td>
                    <ul>
                      <li>certaines minorités</li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>Plats préférés</td>
                  <td>le Kill Bill de Two Amigos à Lyon</td>
                </tr>
                <tr>
                  <td>Plats détéstés</td>
                  <td>la viande de chat en sauce</td>
                </tr>

                <tr>
                  <td>Etape préferée du maquillage</td>
                  <td>mais je me maquille pas je suis pas une pédale !!!!</td>
                </tr>
                <tr>
                  <td>Jour de la semaine préféré</td>
                  <td>samedi</td>
                </tr>
                <tr>
                  <td>Heure de la journée préférée</td>
                  <td>toutes les heures où il fait nuit</td>
                </tr>

                <tr>
                  <td>Mois de l’année préféré</td>
                  <td>avril</td>
                </tr>

                <tr>
                  <td>Ce que je lis en ce moment</td>
                  <td>Spinoza</td>
                </tr>

                <tr>
                  <td>Mes dernières lectures</td>
                  <td>manuel d'utilisation du robot aspirateur</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex-center-column presentation-column">
          <img className="presentation-img" src="nunu.png"></img>
          <h2>Nunu</h2>
          <div class="table-container">
            <table class="table">
              <tbody>
                <tr>
                  <td>Prénom</td>
                  <td>Nunu</td>
                </tr>
                <tr>
                  <td>Nom</td>
                  <td>NUGGETS</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>entre 16 et 23 selon vos préférences</td>
                </tr>
                <tr>
                  <td>Localisation</td>
                  <td>Nunuland</td>
                </tr>
                <tr>
                  <td>Métier</td>
                  <td>
                    ça vous regarde pas c’est super indiscret comme question
                  </td>
                </tr>
                <tr>
                  <td>Taille</td>
                  <td>1m58</td>
                </tr>
                <tr>
                  <td>Pointure</td>
                  <td>37.5</td>
                </tr>
                <tr>
                  <td>J'aime</td>
                  <td>
                    <ul>
                      <li>faire la sieste</li>
                      <li>Les tartes aux pommes de maman</li>
                      <li>Bleach</li>
                      <li>La ville de Lyon 🦁</li>
                      <li>😊</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Je n'aime pas</td>
                  <td>
                    <ul>
                      <li>l'operateur mobile Free</li>
                      <li>Ice Spice</li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>Plats préférés</td>
                  <td>lahmacun et poulet tikka massala</td>
                </tr>
                <tr>
                  <td>Plats détéstés</td>
                  <td>je sais pas je mange de tout moi</td>
                </tr>

                <tr>
                  <td>Etape préferée du maquillage</td>
                  <td>blush</td>
                </tr>
                <tr>
                  <td>Jour de la semaine préféré</td>
                  <td>samedi</td>
                </tr>
                <tr>
                  <td>Heure de la journée préférée</td>
                  <td>15h et 22h</td>
                </tr>

                <tr>
                  <td>Mois de l’année préféré</td>
                  <td>mai car plein de jours fériés et météo sympa</td>
                </tr>

                <tr>
                  <td>Ce que je lis en ce moment</td>
                  <td>lettre d’une inconnue de stefan zweig</td>
                </tr>

                <tr>
                  <td>Mes dernières lectures</td>
                  <td>Data 6</td>
                </tr>

                <tr>
                  <td>e-mail</td>
                  <td>nunu.nuggets@gmail.com</td>
                </tr>

                <tr>
                  <td>Mot de passe</td>
                  <td>1234NunuMolly1234</td>
                </tr>

                <tr>
                  <td>Réponses aux questions de sécurité de mon e-mail</td>
                  <td>
                    Comment s’appelait votre instituteur préféré ? 
                    Hugo DÉCRYPTE
                    <br />
                    <br />
                    Dans quelle ville vos parents se sont-ils rencontrés ? 
                    Ibiza
                    <br />
                    <br />
                    Quel est le nom de jeune fille de votre mère ? 
                    Coulibaly
                  </td>
                </tr>

                <tr>
                  <td>Adresse postale</td>
                  <td>58 Rue de la Soupe, 75006 PARIS</td>
                </tr>

                <tr>
                  <td>Coordonnées bancaires</td>
                  <td>
                    Crédit agricole
                    <br />
                    identifiant : 73519452
                    <br />
                    mdp : 74844619
                  </td>
                </tr>

                <tr>
                  <td>Sujet de mon mémoire</td>
                  <td>Préparation et stabilisation d'un champ non classique en cavité par rétroaction quantique</td>
                </tr>

                <tr>
                  <td>Note de mon mémoire</td>
                  <td>20/20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
