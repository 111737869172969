import { useState, useEffect } from "react";
import "./Accueil.css";

function Accueil() {
  const [nbusers, setNbusers] = useState(Math.floor(Math.random() * (1000000 - 40000 + 1)) + 40000);
  const [btcPrice, seBtcPrice] = useState(null);
  const [kittenImage, setKittenImage] = useState(null);

  function getRandomNumber() {
    const min = 40000;
    const max = 1000000;
    setNbusers(Math.floor(Math.random() * (max - min + 1)) + min);
  }

  function fetchBitcoinPrice() {
    fetch("https://api.coindesk.com/v1/bpi/currentprice/BTC.json")
      .then((response) => response.json())
      .then((data) => {
        const price = data.bpi.USD.rate;
        seBtcPrice(price);
      })
      .catch((error) => {
        console.log(
          "Une erreur est survenue lors de la récupération du prix du Bitcoin :",
          error
        );
      });
  }

  function randomkitty() {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          var response = JSON.parse(xhr.responseText);
          var imageUrl = response[0].url;
          setKittenImage(imageUrl);
        } else {
          console.log(
            "Une erreur s'est produite lors de la récupération de la photo : " +
              xhr.status
          );
        }
      }
    };
    xhr.open("GET", "https://api.thecatapi.com/v1/images/search", true);
    xhr.send();
  }

  useEffect(() => {
    fetchBitcoinPrice();
    randomkitty();
    const bitcoinPriceInterval = setInterval(fetchBitcoinPrice, 5000);
    const randomNumberInterval = setInterval(getRandomNumber, 60000);

    return () => {
      clearInterval(bitcoinPriceInterval);
      clearInterval(randomNumberInterval);
    };
  }, []);

  return (
    <div className="flex-center-column accueil-layout">
      <h1>BIENVENUE SUR LE SITE DE NUNU</h1>
      {
        nbusers && <span>{"Nombre d'utilisateurs actifs : " + nbusers + " 📈"}</span>
      }
      {
        btcPrice && <span>{"₿ Prix du Bitcoin : $" + btcPrice}</span>
      }
      {
        kittenImage && <img className="kitten-image" src={kittenImage}></img>
      }
      <button onClick={randomkitty}>Chaton aléatoire</button>
    </div>
  );
}

export default Accueil;
