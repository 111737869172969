import { useState } from "react";
import "./Tarifs.css";

function Tarifs() {
  return (
    <div className="flex-center-column tarifs-layout">
      <h3>4 millions d'euros !!!!</h3>
      <img className="tarifs-img" src="ca_monte.jpg"></img>
      <span>dépêchez vous car le prix augmente en fonction du nombre d’utilisateurs sur le site (loi de l’offre et la demande)</span>
    </div>
  );
}

export default Tarifs;
